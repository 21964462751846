<template>
  <div class="pressing-main">
    <div class="df-row">
      <div class="df-col row1col1"></div>
      <div class="df-col row2col2"></div>
      <!-- <div class="df-col"></div>
      <div class="df-col"></div> -->
    </div>

    <div class="background"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showWarning: false,
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.pressing-main {
  position: relative;
  width: 100%;
  height: 100%;

  .df-row {
    z-index: 1;
    .df-col {
      background-image: url("../../../assets/img/echart-back1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      z-index: 1;
    }

    .row1col1{
        width: 243px;
        height: 164px;
    }

    .row2col2{
        width: 506px;
        height: 349px;
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../../assets/img/pressing-back1.jpg");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 0;
  }
}
</style>
